<template>
	<div>
		<div class="learning-quiz-content">
			<div class="setup-content">
				<div class="d-flex justify-content-between align-items-center">
					<h4 class="head-title">Skill Assessment Set up</h4>
				</div>
				<div class="skillSets-container">
					<div class="skillSets-header mb-3">
						<h4>Skill Sets</h4>
					</div>
					<div class="skillSets-body">
						<div class="side-menu">
							<draggable v-model="skillSetsData" @start="isDragging = true" @end="onEnd">
								<ul class="side-skill-sets" v-for="(skillSet, skillSetIndex) in skillSetsData" :key="skillSetIndex">
									<div class="skill-set-container">
										<li class="menu-item">
											{{ skillSet.name || `skillSet: ${skillSet.id}` }}
										</li>
										<button class="delete-button" @click="deleteSkillSetInteraction(skillSet.id)">
											X
										</button>
									</div>
									<ul class="sub-menu-item">
										<li v-for="(skillSetItem, skillSetItemIndex) in skillSet.llm_skill_set_items"
											:key="skillSetItemIndex" @click="chooseLlmSkillSetItem(skillSetIndex, skillSetItemIndex)"
											class="menu-item">{{
								skillSetItem.skill_name }}
										</li>
									</ul>
								</ul>
							</draggable>
							<hr />
							<select v-model="skillSetAddId">
								<option value="" default>Select Skill Set</option>
								<option v-for="skillSet in allSkillSets" :key="skillSet.id" :value="skillSet.id">
									{{ skillSet.attributes.name }}
								</option>
							</select>
							<button class="btn add-skill-sets-btn" @click="addSkillSet">
								Add Skill Set
							</button>
						</div>
						<div class="skillSetss-edit" v-if="showSkillSetAssessment == true">
							<div class="edit-body">
								<h4>{{ this.chosenskillSets.attributes.title }}</h4>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Independent / Dependent</label>
											<div class="d-flex">
												<div class="trigger-item">
													<label for="independent">Independent</label>
													<input type="radio" v-model="chosenskillSets.attributes.dependency_type"
														:value="'independent'" id="independent" name="independent">
												</div>
												<div class="trigger-item">
													<label for="dependent">Dependent</label>
													<input type="radio" v-model="chosenskillSets.attributes.dependency_type" :value="'dependent'"
														id="dependent" name="dependent">
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-6">
										<div class="form-group">
											<label>Assessment Type</label>
											<div class="d-flex g-3">
												<div class="trigger-item">
													<label for="pass/fail">Pass/Fail</label>
													<input type="radio" v-model="chosenskillSets.attributes.assessment_type" :value="'passfail'"
														id="passfail" name="passfail">
												</div>
												<div class="trigger-item">
													<label for="thresold">Threshold</label>
													<input type="radio" v-model="chosenskillSets.attributes.assessment_type" :value="'threshold'"
														id="threshold" name="threshold">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group" v-if="chosenskillSets.attributes.assessment_type === 'passfail'">
											<label>Assessment Text</label>
											<input type="textarea" v-model="chosenskillSets.attributes.assessment_text"
												class="form-control" />
										</div>
										<div class="form-group">
											<label>Trigger Response</label>
											<div class="d-flex justify-content-between">
												<div class="trigger-item">
													<label>Hint</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_response" :value="'hint'">
												</div>
												<div class="trigger-item">
													<label>Answer</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_response" :value="'answer'">
												</div>
												<div class="trigger-item">
													<label>None</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_response" :value="'none'">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Trigger Pass</label>
											<div class="d-flex justify-content-between">
												<div class="trigger-item">
													<label>Sadness</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_pass" :value="'sadness'">
												</div>
												<div class="trigger-item">
													<label>Happiness</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_pass" :value="'happiness'">
												</div>
												<div class="trigger-item">
													<label>Fear</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_pass" :value="'fear'">
												</div>
												<div class="trigger-item">
													<label>Surprise</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_pass" :value="'surprise'">
												</div>
												<div class="trigger-item">
													<label>None</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_pass" :value="'none'">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="form-group">
											<label>Trigger Fail</label>
											<div class="d-flex justify-content-between">
												<div class="trigger-item">
													<label>Sadness</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_fail" :value="'sadness'">
												</div>
												<div class="trigger-item">
													<label>Happiness</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_fail" :value="'happiness'">
												</div>
												<div class="trigger-item">
													<label>Fear</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_fail" :value="'fear'">
												</div>
												<div class="trigger-item">
													<label>Surprise</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_fail" :value="'surprise'">
												</div>
												<div class="trigger-item">
													<label>None</label>
													<input type="radio" v-model="chosenskillSets.attributes.trigger_fail" :value="'none'">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="form-group">
											<label>Assessment Narrative Knowledge Base</label>
											<textarea v-model="chosenskillSets.attributes.narrative_base" class="form-control"
												rows="3"></textarea>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="form-group">
											<label>Include this Assessment in interaction</label>
											<label>Checking this option will include this Assessent when assessing the
												answer during the
												interaction</label>
											<div class="d-flex">
												<div class="trigger-item">
													<label for="yes">Yes</label>
													<input type="radio" v-model="chosenskillSets.attributes.include_in_assess" :value="true"
														id="yes" name="include_in_interaction">
												</div>
												<div>
													<label for="no">No</label>
													<input type="radio" v-model="chosenskillSets.attributes.include_in_assess" :value="false"
														id="no" name="include_in_interaction">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-4">
										<div class="form-group">
											<label>Include this Assessment in the feedback?</label>
											<label>Checking this option will include this Assessment in the feedback</label>
											<div class="d-flex">
												<div class="trigger-item">
													<label for="yes">Yes</label>
													<input type="radio" v-model="chosenskillSets.attributes.mandatory" :value="true" id="yes"
														name="mandatory">
												</div>
												<div class="trigger-item">
													<label for="no">No</label>
													<input type="radio" v-model="chosenskillSets.attributes.mandatory" :value="false" id="no"
														name="mandatory">
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label>Show points on assessment</label>
											<div class="d-flex">
												<div class="trigger-item">
													<label for="yes">Yes</label>
													<input type="radio" v-model="chosenskillSets.attributes.show_points" :value="true" id="yes"
														name="show_points">
												</div>
												<div class="trigger-item">
													<label for="no">No</label>
													<input type="radio" v-model="chosenskillSets.attributes.show_points" :value="false" id="no"
														name="show_points">
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-4">
										<div class="form-group">
											<label>Question Needed?</label>
											<div class="d-flex">
												<div class="trigger-item">
													<label for="yes">Yes</label>
													<input type="radio" v-model="chosenskillSets.attributes.question_needed" :value="true"
														id="yes" name="question_needed">
												</div>
												<div class="trigger-item">
													<label for="no">No</label>
													<input type="radio" v-model="chosenskillSets.attributes.question_needed" :value="false"
														id="no" name="question_needed">
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<div class="form-group">
											<label>Feedback Request Type</label>
											<div class="d-flex">
												<div class="trigger-item">
													<label for="feedback_request_type">All</label>
													<input type="radio" v-model="chosenskillSets.attributes.feedback_request_type" :value="'all'"
														id="feedback_request_type" name="feedback_request_type">
												</div>
												<div class="trigger-item">
													<label for="feedback_request_type">Hit</label>
													<input type="radio" v-model="chosenskillSets.attributes.feedback_request_type" :value="'hit'"
														id="feedback_request_type" name="feedback_request_type">
												</div>
												<div class="trigger-item">
													<label for="feedback_request_type">None</label>
													<input type="radio" v-model="chosenskillSets.attributes.feedback_request_type" :value="'none'"
														id="feedback_request_type" name="feedback_request_type">
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- This has two view depending on whether it's pass/fail or threshold-->
								<hr />
								<div class="row">
									<div class="col-md-12">
										<h4 class="mb-5">Feedback Request Items</h4>
									</div>
								</div>

								<div v-if="chosenskillSets.attributes.feedback_request_items">
									<div class="row"
										v-for="(feedback_item, index) in chosenskillSets.attributes.feedback_request_items.data"
										:key="index">
										<div class="col-md-6">
											<ul class="p-0 m-0 w-full">
												<li class="p-0 mb-8">
													<div class="d-block mb-5">
														<div class="d-block mb-5">
															<label class="mb-2">Assessment item label</label>
															<input v-model="feedback_item.attributes.label" type="text" class="form-control mb-5" />
															<label class="d-block mb-2"
																v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
																Level
															</label>
															<input v-model="feedback_item.attributes.level" type="text" class="form-control mb-5"
																v-if="chosenskillSets.attributes.assessment_type == 'threshold'" />
															<label class="mb-2">Points</label>
															<input v-model="feedback_item.attributes.points" type="text" class="form-control mb-5" />
															<button class="btn btn-info" @click="deleteFeedbackRequestItem(feedback_item.id)"
																v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
																Delete
															</button>
														</div>
													</div>
												</li>
											</ul>
										</div>
										<div class="col-md-6">
											<div class="d-block mb-5">
												<label class="mb-2">Assessment Item Description</label>
												<textarea v-model="feedback_item.attributes.description" type="textarea" class="form-control" />
											</div>
										</div>
									</div>
								</div>

								<div class="d-flex mb-5" v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
									<button class="btn" @click="createFeedbackRequestItem()">
										<span class="mx-auto text-sm font-bold">Add new feedback request item</span>
									</button>
								</div>

								<hr />

								<div class="mb-5" v-if="chosenskillSets.attributes.assessment_type == 'threshold'">
									<div class="d-flex">
										<h4 class="mb-5">Assessment Knowledge Base</h4>
									</div>
									<div class="d-flex pb-4 border-b-4">
										<div class="d-block mb-5">
											<label class="block mb-2">Name</label>
											<input v-model="chosenskillSets.attributes.knowbase_name" type="text" class="form-control" />
										</div>
									</div>
									<div class="d-flex border-b-2 mb-6 pb-2">
										<p>Knowledge base entries</p>
									</div>
									<div v-if="chosenskillSets.attributes.assessment_knowledge_items">
										<ul v-for="(knowledge_item, index) in chosenskillSets.attributes.assessment_knowledge_items.data"
											:key="index" class="row mb-5">
											<li class="d-flex col-md-12 justify-content-between">
												{{ knowledge_item.attributes.body }}
												<button class="btn" @click="deleteKnowledgeBaseItem(knowledge_item.id)">Delete</button>
											</li>
										</ul>
									</div>
									<div class="d-flex pb-10 mb-10 border-b-4 flex-column">
										<label>New Knowledge Base Item</label>
										<div class="d-flex">
											<input v-model="new_know_base_item_text" type="text" class="form-control mr-5"
												placeholder="Add new knowledge base item here" />
											<button class="btn" @click="addNewKnowledgeBaseItem()">Add
											</button>
										</div>
									</div>
								</div>
								<div class="d-flex">
									<div class="block mb-5 w-1/3">
										<label class="mb-2">Task</label>
										<input v-model="chosenskillSets.attributes.task" type="text" class="form-control" />
									</div>
								</div>

								<div class="row mb-5">
									<div class="col-md-12">
										<label class="mb-2">Use custom skill label?</label>
										<div class="d-flex">
											<div class="trigger-item">
												<input v-model="chosenskillSets.attributes.use_skill_label" id="use_skill_label-yes"
													type="radio" value="true" name="use_skill_label-yes" class="form-control">
												<label for="use_skill_label-yes"
													class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
											</div>
											<div class="trigger-item">
												<input v-model="chosenskillSets.attributes.use_skill_label" id="use_skill_label-no" type="radio"
													value="false" name="use_skill_label-no" class="form-control">
												<label for="use_skill_label-no" class="">No</label>
											</div>
										</div>
									</div>
								</div>
								<div class="row mb-5">
									<div class="col-md-12">
										<label class="mb-2">Custom skill label</label>
										<div class="d-flex">
											<div class="block mr-5">
												<input v-model="chosenskillSets.attributes.skill_label" type="text" class="form-control" />
											</div>
										</div>
									</div>
								</div>
								<div class="row mb-5">
									<div class="col-md-12 d-flex flex-column">
										<label class="block text-sm font-medium text-slate-700">Admin Notes</label>
										<textarea v-model="chosenskillSets.attributes.admin_notes" type="text" class="form-control" />
									</div>
								</div>

								<div class="row">
									<div class="col-md-12">
										<div class="form-group">
											<button class="btn" @click="saveAssessment">Save Assessment</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../../../services/api";
import draggable from "vuedraggable";
export default {
	name: "llm-email-skill-assessment",
	components: {
		draggable,
	},
	props: ["llmEmailData", "llmType"],
	data() {
		return {
			allSkillSets: [],
			skillSetAddId: "",
			listOrder: 0,
			skillSetsData: [],
			errors: "",
			isActive: false,
			name: "",
			adminNotes: "",
			description: "",
			displayOverallAssessment: false,
			userAnswer: "",
			optionsPassFail: [
				{ text: "Pass/Fail", value: "pass_fail" },
				{ text: "Threshold", value: "threshold" },
			],
			optionsIndependentDependent: [
				{ text: "Independent", value: true },
				{ text: "Dependent", value: false },
			],
			optionsCharacteristics: [{ text: "Open" }, { text: "Closed" }],
			dialogicQuestions: [],
			numberOfAttempts: 0,
			attemptsLimit: 3,
			chosenskillSets: {},
			chosenskillSetsIndex: 0,
			generalKnowledgeBase: "",
			narrativeKnowledgeBase: "",
			newAssKB: "",
			finishedLoading: false,
			chosenskillSetsKey: "",
			messages: [
				{
					question: "",
					answer: "",
					response: "",
				},
			],
			loading: false,
			assessmentLoading: false,
			feedback: "",
			hasStarted: false,
			latestResponse: {},
			showSkillSetAssessment: false,
			characterName: "",
			characterPronouns: "",
			characterAge: "",
			characterJob: "",
			characterCharacteristics: "",
			skillSetsReactionSentiment: "",
			skillSetsReactionEmotion: "",
			narrativeKnowledgeBaseAmount: 0,
			chosenPassFail: "Pass",
			dialogicQuestionRequired: false,
			llmEmailIterations: 0,
			lastMessage: "",
			lastResponse: "",
			emailBody: "",
			emailSubject: "",
			emailChain: [],
			emailToCharacter: "",
			emailFromCharacter: "",
			dialogicQuestionKey: 0,
			assessmentItems: {},
			assessmentItemsKey: 0,
			feedback_assessment_pass_data: {
				label: "",
				description: "",
			},
			feedback_assessment_fail_data: {
				label: "",
				description: "",
			},
			newItem: {
				label: "",
				description: "",
				assessment_level: 0,
			},
			addingNewAssessmentItem: false,
			ccCharacters: [],
			newccCharacter: "",
			enableVoice: false,
			enableAvatar: false,
			reattemptsEnabled: false,
			new_know_base_item_text: "",
		};
	},
	methods: {
		getAllSkillSets() {
			api.getAllLlmSkillSets().then((response) => {
				console.log(response);
				this.allSkillSets = response.data.data;
			});
		},
		addSkillSet() {
			this.createSkillSetInteraction()
		},
		saveAssessment() {
			let assessment_id = this.chosenskillSets.id;
			console.log(this.chosenskillSets.attributes.feedback_request_items)
			this.chosenskillSets.attributes.llm_feedback_request_items_attributes = this.chosenskillSets.attributes.feedback_request_items.data.map((item) => {
				return {
					id: item.id,
					label: item.attributes.label,
					description: item.attributes.description,
					level: item.attributes.level,
					points: item.attributes.points,
				}
			});
			api.updateLlmSkillAssessment(assessment_id, this.chosenskillSets.attributes).then((response) => {
				console.log(response);
				this.$toast.open({
					message: "Assessment Updated",
					type: "success",
				});
			});
		},
		deleteFeedbackRequestItem(id) {
			api.deleteLlmFeedbackRequestItem(id).then((response) => {
				console.log(response);
				api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
					console.log(res.data.data);
					this.chosenskillSets = res.data.data;
					this.showSkillSetAssessment = true;
				});
				this.$toast.open({
					message: "Feedback Request Item Deleted",
					type: "success",
				});
			});
		},
		createFeedbackRequestItem() {
			let payload = {
				llm_skill_assessment_id: this.chosenskillSets.id,
				label: "",
				description: "",
				level: 0,
				points: 0,
			}
			api.createLlmFeedbackRequestItem(payload).then((response) => {
				console.log(response);
				api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
					console.log(res.data.data);
					this.chosenskillSets = res.data.data;
					this.showSkillSetAssessment = true;
				});
				this.$toast.open({
					message: "Feedback Request Item Created",
					type: "success",
				});
			});
		},
		addNewKnowledgeBaseItem() {
			let payload = {
				llm_skill_assessment_id: this.chosenskillSets.id,
				body: this.new_know_base_item_text,
			}
			api.createLlmAssessmentKnowledgeItem(payload).then((response) => {
				console.log(response);
				api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
					console.log(res.data.data);
					this.chosenskillSets = res.data.data;
					this.new_know_base_item_text = "";
					this.showSkillSetAssessment = true;
				});
				this.$toast.open({
					message: "Knowledge Base Item Created",
					type: "success",
				});
			});
		},

		createSkillSetInteraction() {
			if (this.$route.params.id) {
				if (this.interactionSkillSetsList == null) {
					this.listOrder = 0
				} else {
					this.listOrder = this.interactionSkillSetsList.length
				}
				const payload = {
					llm_skill_set_id: this.skillSetAddId,
					llm_email_learn_obj_id: this.llmEmailData.attributes.objectable_id,
					list_order: this.listOrder
				}

				api
					.createLlmSkillSetInteraction(payload).then((response) => {
						console.log("*** response in createSkillSetInteraction", response)
						api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then((response) => {
							console.log(response);
							this.skillSetsData = response.data.data.attributes.card_detail.attributes.llm_skill_set_interactions;
						});
					});
			}
		},
		editKnowledgeBaseItem(index) {
			const kbItem = this.$refs[`kbitem-${index}`][0];
			const kbItemButton = this.$refs[`kbedit-${index}`][0];
			kbItemButton.classList.add("kb-edit-active");
			kbItemButton.innerText = "Save Edit";
			kbItem.contentEditable = true;
			kbItem.focus();
			kbItemButton.addEventListener("click", () => {
				kbItem.contentEditable = false;
				kbItemButton.innerText = "Edit";
				kbItemButton.classList.remove("kb-edit-active");
				this.chosenskillSets.knowledge_base_items[index] = kbItem.innerText;
				this.saveskillSets();
			});
		},
		deleteKnowledgeBaseItem(index) {
			api.deleteLlmAssessmentKnowledgeItem(index).then((response) => {
				console.log(response);
				api.getLlmSkillAssessment(this.chosenskillSets.id).then((res) => {
					console.log(res.data.data);
					this.chosenskillSets = res.data.data;
					this.showSkillSetAssessment = true;
				});
				this.$toast.open({
					message: "Knowledge Base Item Deleted",
					type: "success",
				});
			});
			// this.chosenskillSets.knowledge_base_items.splice(index, 1);
		},
		editAssessmentItem(index) {
			// This method needs to be worked around as the event listener
			// Removes the one already attached to the edit button
			const assessmentItemLabel = this.$refs[`assessment-item-label-${index}`][0];
			const assessmentItemDescription = this.$refs[`assessment-item-description-${index}`][0];
			const assessmentItemLevel = this.$refs[`assessment-item-level-${index}`][0];
			const assessmentItemButton = this.$refs[`assessment-item-${index}`][0];
			assessmentItemButton.classList.add("kb-edit-active");
			assessmentItemButton.innerText = "Save Edit";
			assessmentItemLabel.contentEditable = true;
			assessmentItemDescription.contentEditable = true;
			assessmentItemLevel.contentEditable = true;
			assessmentItemLabel.focus();
			assessmentItemButton.addEventListener("click", () => {
				assessmentItemLabel.contentEditable = false;
				assessmentItemDescription.contentEditable = false;
				assessmentItemLevel.contentEditable = false;
				assessmentItemButton.innerText = "Edit";
				assessmentItemButton.classList.remove("kb-edit-active");
				this.saveskillSets();
			}, { once: true });
		},
		deleteAssessmentItem(index) {
			delete this.assessmentItems[index]
			this.assessmentItemsKey++;
			this.saveskillSets();
		},
		saveAssessmentItem() {
			let assessmentItemLength = Object.keys(this.assessmentItems).length;
			this.assessmentItems[assessmentItemLength] = this.newItem;
			this.newItem = {
				label: "",
				description: "",
				assessment_level: 0,
			}
			this.addingNewAssessmentItem = false;
			this.saveskillSets();
		},
		cancelAssessmentItem() {
			this.newItem = {
				label: "",
				description: "",
				assessment_level: 0,
			}
			this.addingNewAssessmentItem = false;
		},
		addNewAssessmentItem() {
			this.addingNewAssessmentItem = true;
		},
		addKnowledgeBaseItem() {
			if (this.newAssKB === "") return;
			this.chosenskillSets.knowledge_base_items.push(this.newAssKB);
			this.newAssKB = "";
		},
		addskillSets() {
			let newskillSets = {
				new_skillSets: true,
				title: "",
				skillSets: "",
				assessment_knowledgebase_name: "",
				knowledge_base_items: [],
				threshold: 0,
				mandatory: false,
				question_needed: false,
				task: "",
				narrative_knowledge_base: "",
				admin_notes: "",
				pass_fail: true
			};
			this.skillSetsData.push(newskillSets);
			this.chosenskillSetsIndex = this.skillSetsData.length - 1;
			this.chosenskillSets = this.skillSetsData[this.chosenskillSetsIndex];
			this.showskillSetss = true;
		},
		chooseLlmSkillSetItem(skillSetId, skillSetItemId) {
			let llmAssessmentSkillId = this.skillSetsData[skillSetId].llm_skill_set_items[skillSetItemId].assessment.id
			console.log(llmAssessmentSkillId);
			api.getLlmSkillAssessment(llmAssessmentSkillId).then((res) => {
				console.log(res.data.data);
				this.chosenskillSets = res.data.data;
				this.showSkillSetAssessment = true;
			});
		},
		deleteSkillSetInteraction(index) {
			api
				.deleteSkillSetInteraction(index)
				.then((res) => {
					console.log(res);
					utilFunctionService.hideLoader();
					api.fetchLoDetails(this.$route.params.id, this.$route.query.id).then((response) => {
						this.skillSetsData = response.data.data.attributes.card_detail.attributes.llm_skill_set_interactions;
					});
				})
				.catch();
		},
		getskillSetss() {
			this.finishedLoading = false;
			api.getSkillSetInteractions(this.llmEmailData.attributes.card_detail.id)
				.then((res) => {
					console.log(res);
					res.data.data.forEach((skillSets) => {
						console.log(skillSets.attributes);
						this.skillSetsData.push(skillSets.attributes);
					});
					this.skillSetsData = res.data.data;
					if (this.skillSetsData.length > 0) {
						this.chosenskillSets = this.skillSetsData[0];
						this.showskillSetss = true;
					}
					this.finishedLoading = true;
					utilFunctionService.hideLoader();
				})
				.catch();
		},
		refreshData() {
			const cardDetail = this.llmEmailData.attributes.card_detail.attributes;
			this.name = cardDetail.title;
			this.adminNotes = cardDetail.admin_notes;
			this.description = cardDetail.description;
			this.narrativeKnowledgeBase = [];
			if (cardDetail.narrative_knowledge_base.length > 1) {
				this.narrativeKnowledgeBase.push(cardDetail.narrative_knowledge_base)
			} else {
				this.narrativeKnowledgeBase = [""];
			}
			this.narrativeKnowledgeBaseAmount = this.narrativeKnowledgeBase.length;
			this.generalKnowledgeBase = cardDetail.general_knowledge_base;
			this.characterName = cardDetail.character_name;
			this.characterAge = cardDetail.character_age;
			this.characterJob = cardDetail.character_job;
			this.characterPronouns = cardDetail.character_pronouns;
			this.characterCharacteristics = cardDetail.characteristics;
			this.skillSetsReactionSentiment = cardDetail.skillSets_reaction_sentiment;
			this.skillSetsReactionEmotion = cardDetail.skillSets_reaction_emotion;
			this.reattemptsEnabled = cardDetail.enable_reattempts;
			this.maxReattempts = cardDetail.max_reattempts;
			this.enableLlmAssessments = cardDetail.enable_llm_assessment;
			if (cardDetail.pass_fail) {
				this.chosenPassFail = "pass";
			} else {
				this.chosenPassFail = "fail";
			}
			if (cardDetail.skillSetss) {
				this.skillSetsData = cardDetail.skillSetss;
			} else {
				this.skillSetsData = [];
			}
			this.switchTab('basic-information');
		},
		onEnd() {
			const fd = new FormData();
			this.skillSetsData.map((data, index) => {
				fd.append("skillSets[][id]", data.id);
				fd.append("skillSets[][skillSets_order]", index + 1);
			});
			api
				.reorderskillSetss(this.llmEmailData.attributes.card_detail.id, this.llmEmailData.attributes.card_type, fd)
				.then(() => { })
				.catch();
		},
	},
	mounted() {
		this.getAllSkillSets();
		this.skillSetsData = this.llmEmailData.attributes.card_detail.attributes.llm_skill_set_interactions;
	},
};
</script>

<style>
.knowledge-base-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 16px;
}

.knowledge-base-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.knowledge-base-item h5 {
	font-weight: bold;
}

.kb-btn-container {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.text-area {
	width: 400px;
	font-family: sans-serif;
	height: 200px;
	margin-bottom: 18px;
	padding: 8px;
	line-height: 1.6;
}

.skillSetss-body {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.side-menu {
	display: flex;
	flex-direction: column;
	width: 20%;
	border-right: 1px solid black;
	padding: 16px;
	justify-content: space-between;
	height: 100%;
}

.menu-item {
	width: 90px;
	border: 1px solid black;
	margin-bottom: 8px;
	border-radius: 5px;
	list-style-type: none;
	background: white;
	color: black;
	font-weight: bold;
	cursor: pointer;
	padding: 8px;
}

.menu-item:hover {
	background: black;
	color: white;
}

.skillSetss-edit {
	width: 80%;
	padding: 16px;
}

.skillSetss-container {
	width: 100%;
}

.skillSetss-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.kb_form {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: flex-start;
}

.kb_form--item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 16px;
}

.kb_form--itemhori {
	flex-direction: row;
}

.kb_form--item label,
p {
	margin-bottom: 8px;
	font-weight: bold;
}

.kb_form--item textarea {
	width: 100%;
	font-family: sans-serif;
	height: 100px;
	margin-bottom: 18px;
	padding: 8px;
	line-height: 1.6;
}

.kb {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.kb-input {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 16px;
}

.kb-input input {
	width: 50%;
	padding: 8px;
	border-radius: 5px;
}

.btn {
	border-radius: 5px;
	border: 1px solid black;
	background: white;
	color: black;
	font-weight: bold;
	cursor: pointer;
	padding: 8px;
	height: 50px;
}

.btn:hover {
	background: black;
	color: white;
}

.side-skillSets {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	width: 100%;
}

.delete-button {
	background: red;
	color: white;
	font-weight: bold;
	cursor: pointer;
	padding: 5px;
	height: 25px;
	border-radius: 5px;
	border: 1px solid black;
}

.delete-button:hover {
	background: white;
	color: red;
	border: 1px solid red;
}

.kb_item--column {
	display: flex;
	flex-direction: column;
}

.learner-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.messages-area {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 1000px;
}

.bubble {
	border-radius: 15px;
	padding: 8px;
	margin-bottom: 16px;
	width: 50%;
}

.learner-bubble {
	background: green;
	color: white;
	font-weight: bold;
	justify-self: flex-end;
	align-self: flex-end;
}

.robot-bubble {
	background: blue;
	color: white;
	font-weight: bold;
	justify-self: flex-start;
	align-self: flex-start;
}

.input-area {
	color: black;
	width: 400px;
}

.chat {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 32px;
}

.button {
	padding: 8px;
	border-radius: 5px;
	background: white;
	border: 2px solid green;
	margin-top: 16px;
}

.button:hover {
	background: green;
	color: white;
	cursor: pointer;
	transition: all 0.2s;
}

.button-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
}

.up-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}

.up-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
	transform: translateY(30px);
}

.feedback {
	width: 50%;
	margin: 25px auto;
	text-align: left;
	line-height: 1.6;
}

.spacer {
	display: block;
	width: 50%;
	margin: 0 auto;
	height: 1px;
	background: black;
}

.skillSets-reaction-container {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.skillSets-reaction-container .skillSetss-body {
	justify-content: flex-start;
	gap: 16px;
}

.skillSets-reaction-container button {
	width: 250px;
}

.form-checkbox .form-check-input {
	position: relative;
	margin-left: 3px;
	margin-right: 18px;
}

.side-skill-sets {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.skill-set-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
}

.sub-menu-item {
	margin-left: 16px;
	width: 100%;
	margin-bottom: 8px;

	&:last-child {
		margin-bottom: 16px;
	}
}

.skillSets-body {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.trigger-item {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: baseline;
	gap: 20px;
	margin: 0 20px 0 0;

	label {
		margin-bottom: 0;
	}
}

.form-control {
	border: 1px solid #e2e8f0;
}

.add-skill-sets-btn {
	margin-top: 20px;
}
</style>
import { render, staticRenderFns } from "./LearnerDialogic.vue?vue&type=template&id=ff4c241a&scoped=true"
import script from "./LearnerDialogic.vue?vue&type=script&lang=js"
export * from "./LearnerDialogic.vue?vue&type=script&lang=js"
import style0 from "./LearnerDialogic.vue?vue&type=style&index=0&id=ff4c241a&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff4c241a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_d58553fa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ff4c241a')) {
      api.createRecord('ff4c241a', component.options)
    } else {
      api.reload('ff4c241a', component.options)
    }
    module.hot.accept("./LearnerDialogic.vue?vue&type=template&id=ff4c241a&scoped=true", function () {
      api.rerender('ff4c241a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LearnerDialogic.vue"
export default component.exports
import { render, staticRenderFns } from "./WatsonAdmin.vue?vue&type=template&id=1f3fc406"
import script from "./WatsonAdmin.vue?vue&type=script&lang=js"
export * from "./WatsonAdmin.vue?vue&type=script&lang=js"
import style0 from "./WatsonAdmin.vue?vue&type=style&index=0&id=1f3fc406&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/build_d58553fa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f3fc406')) {
      api.createRecord('1f3fc406', component.options)
    } else {
      api.reload('1f3fc406', component.options)
    }
    module.hot.accept("./WatsonAdmin.vue?vue&type=template&id=1f3fc406", function () {
      api.rerender('1f3fc406', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/WatsonAdmin.vue"
export default component.exports